import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import formatter from '../../../../global/formater';
import FIGCard from '../../FIGCard/FIGCard';
import { IFinancialIndependenceGoals } from '../../types';
import { useStyles } from '../style'

interface IFinancialIndependenceGoalsProps { FIGoals: IFinancialIndependenceGoals; }

const FinancialIndependenceGoals: React.FC<IFinancialIndependenceGoalsProps> = ({ FIGoals }) => {
    const classes = useStyles();
    React.useEffect(() => { }, [FIGoals]);

    const accumulated = () => {
        if (FIGoals.updatedAccumulated) {
            return FIGoals.updatedAccumulated;
        }
        return FIGoals.totalAccumulated;
    };
    return (
        <>
            {window.screen.width <= 767 ? (
                <div style={{ margin: '2vh' }}>
                    <Typography className={classes.subHeading} variant="h6">Meta até a independência financeira</Typography>
                    <div className={classes.container}>
                        <div>
                            <FIGCard
                                header="Seu número"
                                value={formatter.format(FIGoals.independenceGoal)}
                                subtitle="PARA A INDEPENDÊNCIA"
                            />
                        </div>
                        <div><FIGCard header="Acumulado até o momento" value={formatter.format(accumulated())} /></div>
                        <div><FIGCard header="Até a meta" value={`${FIGoals.timeToIndependence}`} subtitle="ANOS" /></div>
                        <div>
                            <FIGCard
                                header="% alcançada da meta"
                                value={`${((accumulated() * 100) / FIGoals.independenceGoal).toFixed(2)}%`}
                                negative
                                color={FIGoals.achievedIncoming > 0 ? 'positive' : 'negative'}
                            />
                        </div>
                    </div>
                    <Typography className={classes.subHeading} variant="h6">Saldo da independência financeira</Typography>
                    <div>
                        <div>
                            <FIGCard
                                header="Total guardado até o momento"
                                value={formatter.format(FIGoals.accumulated)}
                                subtitle=""
                            />
                        </div>
                        <div><FIGCard header="Esperado" value={formatter.format(FIGoals.expectedAccumulated)} /></div>
                        <div>
                            <FIGCard
                                header="Diferença"
                                value={formatter.format(FIGoals.accumulated - FIGoals.expectedAccumulated)}
                                subtitle=""
                                negative
                                color={FIGoals.accumulated - FIGoals.expectedAccumulated > 0 ? 'positive' : 'negative'}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{ margin: '2vh' }}>
                    <Typography className={classes.subHeading} variant="h6">Meta até a independência financeira</Typography>
                    <Grid className={classes.container} container spacing={2} justify="space-between">
                        <Grid item xs={3}>
                            <FIGCard
                                header="Seu número"
                                value={formatter.format(FIGoals.independenceGoal)}
                                subtitle="PARA A INDEPENDÊNCIA"
                            />
                        </Grid>
                        <Grid item xs={3}><FIGCard header="Acumulado até o momento" value={formatter.format(accumulated())} /></Grid>
                        <Grid item xs={3}><FIGCard header="Até a meta" value={`${FIGoals.timeToIndependence}`} subtitle="ANOS" /></Grid>
                        <Grid item xs={3}>
                            <FIGCard
                                header="% alcançada da meta"
                                value={`${((accumulated() * 100) / FIGoals.independenceGoal).toFixed(2)}%`}
                                negative
                                color={FIGoals.achievedIncoming > 0 ? 'positive' : 'negative'}
                            />
                        </Grid>
                    </Grid>
                    <Typography className={classes.subHeading} variant="h6">Saldo da independência financeira</Typography>
                    <Grid container spacing={2} justify="space-between">
                        <Grid item xs={5}>
                            <FIGCard
                                header="Total guardado até o momento"
                                value={formatter.format(FIGoals.accumulated)}
                                subtitle=""
                            />
                        </Grid>
                        <Grid item xs={4}><FIGCard header="Esperado" value={formatter.format(FIGoals.expectedAccumulated)} /></Grid>
                        <Grid item xs={3}>
                            <FIGCard
                                header="Diferença"
                                value={formatter.format(FIGoals.accumulated - FIGoals.expectedAccumulated)}
                                subtitle=""
                                negative
                                color={FIGoals.accumulated - FIGoals.expectedAccumulated > 0 ? 'positive' : 'negative'}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}

        </>
    );
};
export default FinancialIndependenceGoals;

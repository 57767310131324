import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import ProtectionCard from '../protectionCard/protectionCard';
import { IInventoryFreeAssets, IProtectionNeeded } from '../types';
import { useStyles } from './style'

interface IProtectionNeededProps extends IProtectionNeeded {
    totalInvested: number;
    diff: { current: number; future: number };
    freeAssets: IInventoryFreeAssets;
}

const ProtectionNeeded: React.FC<IProtectionNeededProps> = ({ current, future, totalInvested, diff, freeAssets }) => {
    const classes = useStyles();
    return (
        <>
            {window.screen.width <= 767 ? (
                <div className='container'>
                    <h5 className='fw-bold'>Necessidade total de proteção</h5>
                    <div className='d-column'>
                        <div>
                            <ProtectionCard
                                lifeQuality={diff.current > 0 ? diff.current : 0}
                                successionCost={current.successionCost}
                                freeAssets={freeAssets}
                                inventoryFreeAssets={current.inventoryFreeAssets}
                                type="current"
                                totalInvested={totalInvested}
                            />
                        </div>
                        <div>
                            <ProtectionCard
                                freeAssets={freeAssets}
                                lifeQuality={diff.future > 0 ? diff.future : 0}
                                successionCost={future.successionCost}
                                inventoryFreeAssets={future.inventoryFreeAssets}
                                type="future"
                                totalInvested={totalInvested}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className='container'>
                    <Typography variant="h5">Necessidade total de proteção</Typography>
                    <Grid container spacing={2} className={classes.content}>
                        <Grid item xs={6}>
                            <ProtectionCard
                                lifeQuality={diff.current > 0 ? diff.current : 0}
                                successionCost={current.successionCost}
                                freeAssets={freeAssets}
                                inventoryFreeAssets={current.inventoryFreeAssets}
                                type="current"
                                totalInvested={totalInvested}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ProtectionCard
                                freeAssets={freeAssets}
                                lifeQuality={diff.future > 0 ? diff.future : 0}
                                successionCost={future.successionCost}
                                inventoryFreeAssets={future.inventoryFreeAssets}
                                type="future"
                                totalInvested={totalInvested}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}





        </>
    );
};
export default ProtectionNeeded;

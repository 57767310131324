import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { IProjection } from '../../flow/types';
import ForFamily from '../forFamily/forFamily';
import PatrimonyDuration from '../patrimonyDuration/patrimonyDuration';
import { ILifeQuality } from '../types';
import { useStyles } from './style'

interface ILifeQualityProps extends ILifeQuality {
    projection: IProjection;
    diff: number;
}
const LifeQuality: React.FC<ILifeQualityProps> = ({
    monthlyIncoming,
    duration,
    necessaryPatrimony,
    projection,
    projectionDuration,
    totalInvested,
    diff,
}) => {
    const classes = useStyles();

    return (
        <>
            {window.screen.width <= 767 ? (
                <div className='container'>
                    <h5 className='fw-bold text-center'>Qualidade de vida para sua família (seguro de vida temporário, invalidez, doenças graves)</h5>
                    <div className='d-column'>
                        <ForFamily
                            diff={diff}
                            duration={duration}
                            monthlyIncoming={monthlyIncoming}
                            necessaryPatrimony={necessaryPatrimony}
                            totalInvested={totalInvested}
                        />
                        <PatrimonyDuration duration={projectionDuration} patrimonyProjection={projection} />
                    </div>
                </div>
            ) : (
                    <div className='container'>
                        <Typography variant="h5">
                            Qualidade de vida para sua família (seguro de vida temporário, invalidez, doenças graves)
                        </Typography>
                        <Grid className={classes.content} container spacing={2}>
                            <Grid item xs={6}>
                                <ForFamily
                                    diff={diff}
                                    duration={duration}
                                    monthlyIncoming={monthlyIncoming}
                                    necessaryPatrimony={necessaryPatrimony}
                                    totalInvested={totalInvested}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <PatrimonyDuration duration={projectionDuration} patrimonyProjection={projection} />
                            </Grid>
                        </Grid>
                    </div>
                )
            }
        </>

    );
};
export default LifeQuality;
